
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import { EntityId } from '@/api/types'

@Component
export default class NotFoundView extends Vue {
  @Prop(String) protected readonly id: EntityId | undefined
  @Getter private getWikidataUrl: any

  private get wikidata() {
    if (this.id) {
      return this.getWikidataUrl(this.id)
    }
  }
}
